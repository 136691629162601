<template>
  <div class="page-container">
    <div class="page-content fadeInDown">
      <button class="buttonPrimary" v-on:click="openModal('CreateUser')">Créer utilisateur</button>
      <button class="buttonPrimary space-left" v-on:click="openModal('ImportUsers')">Importer utilisateurs</button>
      <table>
        <tbody>
          <tr>
            <th v-on:click="sort('nom')">
              <label>Nom</label>
              <div class="th-icon-sort">
                <i class="icon-arrow-down" v-if="displayIcon('nom','desc')"></i>
                <i class="icon-arrow-up" v-if="displayIcon('nom','asc')"></i>
              </div>
            </th>
            <th v-on:click="sort('prenom')">
              <label>Prénom</label>
              <div class="th-icon-sort">
                <i class="icon-arrow-down" v-if="displayIcon('prenom','desc')"></i>
                <i class="icon-arrow-up" v-if="displayIcon('prenom','asc')"></i>
              </div>
            </th>
            <th v-on:click="sort('pseudo')">
              <label>Pseudo</label>
              <div class="th-icon-sort">
                <i class="icon-arrow-down" v-if="displayIcon('pseudo','desc')"></i>
                <i class="icon-arrow-up" v-if="displayIcon('pseudo','asc')"></i>
              </div>
            </th>
            <th v-on:click="sort('dateNaissance')">
              <label>Date de naissance</label>
              <div class="th-icon-sort">
                <i class="icon-arrow-down" v-if="displayIcon('dateNaissance','desc')"></i>
                <i class="icon-arrow-up" v-if="displayIcon('dateNaissance','asc')"></i>
              </div>
            </th>
            <th v-on:click="sort('email')">
              <label>Email</label>
              <div class="th-icon-sort">
                <i class="icon-arrow-down" v-if="displayIcon('email','desc')"></i>
                <i class="icon-arrow-up" v-if="displayIcon('email','asc')"></i>
              </div>
            </th>
            <th v-on:click="sort('groupeId')">
              <label>Groupe</label>
              <div class="th-icon-sort">
                <i class="icon-arrow-down" v-if="displayIcon('groupeId','desc')"></i>
                <i class="icon-arrow-up" v-if="displayIcon('groupeId','asc')"></i>
              </div>
            </th>
            <th v-on:click="sort('dateLastConnexion')">
              <label>Dernière connexion</label>
              <div class="th-icon-sort">
                <i class="icon-arrow-down" v-if="displayIcon('dateLastConnexion','desc')"></i>
                <i class="icon-arrow-up" v-if="displayIcon('dateLastConnexion','asc')"></i>
              </div>
            </th>
            <th><label></label></th>
            <th><label></label></th>
            <th><label></label></th>
            <th><label></label></th>
            <th><label></label></th>
            <th><label></label></th>
          </tr>
          <tr v-for="user in sortedUsers" :key="user.id">
            <td><input spellcheck='false' class="input-invisible input-large" name="nom" type="text" :value="user.nom" @blur="updateData($event, user.id)" autocomplete="off"/></td>
            <td><input spellcheck='false' class="input-invisible" name="prenom" type="text" :value="user.prenom" @blur="updateData($event, user.id)" autocomplete="off"/></td>
            <td><input spellcheck='false' class="input-invisible input-large" name="pseudo" type="text" :value="user.pseudo" @blur="updateData($event, user.id)" autocomplete="off"/></td>
            <td><input spellcheck='false' class="input-invisible" name="dateNaissance" type="date" :value="user.dateNaissance" @blur="updateData($event, user.id)" autocomplete="off"/></td>
            <td><input spellcheck='false' class="input-invisible input-large" name="email" type="text" :value="user.email" @blur="updateData($event, user.id)" autocomplete="off"/></td>

            <td>
              <select class="" name="groupeId" @change="updateData($event, user.id)">
                <option v-for="group in groups" :key="group.id" :selected="group.id === user.groupeId" :value="group.id">
                  {{ group.titre }}
                </option>
              </select>
            </td>

            <td>{{ formatDate(user.dateLastConnexion) }}</td>

            <td><button title="Supports" class="button-invisible" @click="openModal('UserSupports', { idUser: user.id })"><i class="icon-cours"></i></button></td>
            <td><button title="Mot de passe" class="button-invisible" @click="openModal('Password', { idUser: user.id })"><i class="icon-key"></i></button></td>
            <td><button title="Compte validé" class="button-invisible" @click="toggleUserValidation(user)"><i v-bind:class="user.validated ? 'icon-ok' : 'icon-ok invert'"></i></button></td>
            <td><button title="Accès autorisé" class="button-invisible" @click="toggleBanUser(user)"><i v-bind:class="user.authorized ? 'icon-validate' : 'icon-invalidate'"></i></button></td>
            <td><button title="Supprimer" class="button-invisible" @click="openModal('DeleteUser', { idUser: user.id })"><i class="icon-delete"></i></button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <DeleteUserModal @refresh="getUsersList()"/>
    <CreateUserModal v-bind:showUserModal="showUserModal" @closedCreateModal="showUserModal=false" @refresh="getUsersList()"/>
    <ImportUsersModal v-bind:showImportUsersModal="showImportUsersModal" @closedImportUsersModal="showImportUsersModal=false" @refresh="getUsersList()"/>

    <PasswordModal v-bind:idUserPassword="idUserPassword" @closedPasswordModal="idUserPassword=0"/>
    <UserSupportsModal v-bind:idUserSupports="idUserSupports" @closedUserSupportsModal="idUserSupports=0"/>

  </div>
</template>

<style src='./listUsersPage.css' scoped></style>

<script>
import Api from '@/services/Api'

import DeleteUserModal from '@/modals/DeleteUserModal/DeleteUserModal'
import PasswordModal from '@/modals/PasswordModal/PasswordModal'
import CreateUserModal from '@/modals/CreateUserModal/CreateUserModal'
import UserSupportsModal from '@/modals/UserSupportsModal/UserSupportsModal'
import ImportUsersModal from '@/modals/ImportUsersModal/ImportUsersModal'

import moment from 'moment/moment'

export default {
  name: 'ListUsersPage',
  data () {
    return {
      users: [],
      groups: [],
      currentSort: 'nom',
      currentSortDir: 'asc',

      idUserToDelete: 0,
      idUserPassword: 0,
      idUserSupports: 0,
      showUserModal: false,
      showImportUsersModal: false,

      configUrl: {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }
    }
  },
  components: {
    DeleteUserModal,
    PasswordModal,
    CreateUserModal,
    UserSupportsModal,
    ImportUsersModal
  },
  methods: {
    openModal (modalName, params) {
      this.emitter.emit('openModal' + modalName, params);
    },
    getUsersList: function () {
      Api().get('/users', this.configUrl)
        .then((response) => {
          this.users = response.data.users
        })
        .catch(() => {
        })
    },
    getGroups: function () {
      Api().get('/users/groups', this.configUrl)
        .then((response) => {
          this.groups = response.data.groups
        })
        .catch(() => {
        })
    },
    updateData: function (e, idUser) {
      let param = {
        id: idUser,
        fieldName: e.target.name,
        fieldValue: e.target.value
      }

      Api().patch('/users/updateField', param, this.configUrl)
        .then(() => {
          this.getUsersList()
        })
        .catch(() => {
        })
    },
    toggleBanUser: function (user) {
      let param = {
        id: user.id
      }

      Api().patch('/users/toggleBan', param, this.configUrl)
        .then(() => {
          user.authorized = !user.authorized
        })
        .catch(() => {
        })
    },
    toggleUserValidation: function (user) {
      let param = {
        id: user.id
      }

      Api().patch('/users/toggleValidation', param, this.configUrl)
        .then(() => {
          user.validated = !user.validated
        })
        .catch(() => {
        })
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      } else {
        this.currentSortDir = 'asc'
      }
      this.currentSort = s
    },
    displayIcon: function (column, sorting) {
      if (this.currentSort === column && this.currentSortDir === sorting) {
        return true
      }
      return false
    },
    formatDate: function (value) {
      if (!value) return ''
      let date = moment(value).format('DD/MM/YYYY - HH:mm')
      return date
    }
  },
  computed: {
    sortedUsers: function () {
      let sorted = this.users

      sorted.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === 'desc') 
          modifier = -1

        if (a[this.currentSort] < b[this.currentSort] || a[this.currentSort] == null) {
          return -1 * modifier
        }
        if (a[this.currentSort] > b[this.currentSort] || b[this.currentSort] == null) {
          return 1 * modifier
        }
        return 0
      })

      return sorted
    }
  },
  mounted () {
    document.title = 'Utilisateurs'

    this.getGroups()
    this.getUsersList()
  }
}
</script>
