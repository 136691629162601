<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header><h3>Supprimer ce bloc ?</h3></template>

    <template v-slot:body>
    </template>

    <template v-slot:footer>
      <div class='buttons-container'>
        <button class="buttonPrimary" @click="deleteBlock">
          OK
        </button>
        <a @click="closeModal">
          Annuler
        </a>
      </div>
    </template>
  </BasicModal>
</template>

<style src='../BasicModal/basicModal.css' scoped></style>

<script>
import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'DeleteBlockModal',
  data () {
    return {
      showedModal: false,
      idBlockToDelete: 0
    }
  },
  components: {
    BasicModal
  },
  methods: {
    deleteBlock: function () {
      let configUrl = {
        data: {
          id: this.idBlockToDelete
        },
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().delete('/blocks', configUrl)
        .then(() => {
          this.$emit('refresh', true)
          this.showedModal = false
        })
        .catch(() => {
          this.showedModal = false
        })
    },
    showModal: function (idBlockToDelete) {
      this.idBlockToDelete = idBlockToDelete
      this.showedModal = true
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }
      
      this.idBlockToDelete = 0
      this.showedModal = false
    }
  },
  mounted () {
    this.emitter.on("openModalDeleteBlock", params => {
      this.showModal(params.idBloc)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
