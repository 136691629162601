<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header><h3>Edition d'une page</h3></template>
    <template v-slot:body>
    </template>
    <template v-slot:footer>

      <form id='form-connexion' ref='form' v-on:submit='editSupport'>
        <div class='form-content'>

          <input type='hidden' name='id' :value='supportToEdit.id'>

          <div class='container-champ'>
            <label class='label-champ'>Titre : </label>
            <input spellcheck='false' class='textfield-champ' type='text' name='titre' placeholder='Titre' autocomplete='off' :value='supportToEdit.titre'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Miniature : </label>
            <div>
              <input type="file" id="upload-button" ref="upload-button" v-on:change="handleFileUpload()">
            </div>
            <div class="container-miniature"><img :src="url"></div>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Ordre : </label>
            <input spellcheck='false' class='textfield-champ' type='number' step='1' min='1' name='ordre' placeholder='1' autocomplete='off' required :value='supportToEdit.ordre'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Type de support : </label>
            <div class="formfield-select--container">
              <select name='supporttypeId'>
                <option v-for="support in supporttypes" :key="support.id" :value='support.id' :selected='support.id === supportToEdit.supporttypeId'>
                  {{ support.titre }}
                </option>
              </select>
            </div>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Theme : </label>
            <div class="formfield-select--container">
              <select name='themeId'>
                <option v-for="theme in themes" :key="theme.id" :value='theme.id' :selected='theme.id === supportToEdit.themeId'>
                  {{ theme.titre }}
                </option>
              </select>
            </div>
          </div>

          <div class='buttons-container'>
            <input type='submit' class='buttonPrimary' value='Valider'/>
            <a @click="closeModal">
              Annuler
            </a>
          </div>
        </div>
      </form>
    </template>
  </BasicModal>
</template>

<style src='../CreateSupportModal/createSupportModal.css' scoped></style>

<script>
import Api from '@/services/Api'
import ApiCdn from '@/services/ApiCdn'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'EditPageModal',
  data () {
    return {
      showedModal: false,
      miniature: null,
      url: '',
      defaulturl: '/images/undefined.svg',
      supportToEdit: {},
      supporttypes: [],
      themes: []
    }
  },
  components: {
    BasicModal
  },
  methods: {
    handleFileUpload: function () {
      this.miniature = this.$refs['upload-button'].files[0]

      if (this.miniature && this.miniature !== null) {
        this.url = URL.createObjectURL(this.miniature)
      } else {
        if (this.supportToEdit.miniatureName && this.supportToEdit.miniatureName !== null) {
          this.url = process.env.VUE_APP_CDN_PATH + '/supports/' + this.supportToEdit.id + '/cover/' + this.supportToEdit.miniatureName
        } else {
          this.url = this.defaulturl
        }
      }
    },
    editSupport: async function (e) {
      e.preventDefault()

      if (!e.target.elements.titre.value || e.target.elements.titre.value === null) {
        return
      }

      function getBase64 (file) {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
      }
      let base64 = null
      if (this.miniature && this.miniature !== null) {
        base64 = await getBase64(this.miniature)
      }

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      let dataEdition = {
        id: e.target.elements.id.value,
        titre: e.target.elements.titre.value,
        supporttypeId: e.target.elements.supporttypeId.value,
        themeId: e.target.elements.themeId.value,
        ordre: e.target.elements.ordre.value
      }

      Api().put('/supports', dataEdition, configUrl)
      .then(async() => {
        ApiCdn().put('/supports', { miniature: base64, oldFileName: this.supportToEdit.miniatureName, id: this.supportToEdit.id }, configUrl)
        .then(async(fileNameData) => {
          if (fileNameData != null && fileNameData.data && fileNameData.data != null) {
            dataEdition.miniatureName = fileNameData.data.fileName
            await Api().put('/supports', dataEdition, configUrl)
          }
          this.closeModal(null)
          this.$emit('refresh', true)
        })
      })
      .catch(() => {
        this.closeModal(null)
      })      
    },
    showModal: async function (idPageToEdit) {
      let configUrl = {
        headers: { authorization: 'Token ' + localStorage.getItem('user-token') },
        params: { idSupport: idPageToEdit }
      }

      Api().get('/supports/getSupportForEdition', configUrl)
        .then((response) => {
          this.supportToEdit = response.data.support
          this.miniatureName = null

          if (this.supportToEdit.miniatureName && this.supportToEdit.miniatureName !== null) {
            this.url = process.env.VUE_APP_CDN_PATH + '/supports/' + this.supportToEdit.id + '/cover/' + this.supportToEdit.miniatureName
          } else {
            this.url = this.defaulturl
          }

          Api().get('/supporttypes', configUrl)
            .then((response) => {
              this.supporttypes = response.data.supporttypes
              this.showedModal = true
            })
            .catch(() => {
            })

          Api().get('/themes', configUrl)
            .then((response) => {
              this.themes = response.data.themes
            })
        })
        .catch(() => {
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }

      this.$emit('closedEditModal', true)
      this.showedModal = false
    }
  },
  mounted () {
    this.emitter.on("openModalEditPage", params => {
      this.showModal(params.idSupport)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
