<template>
  <div class="page-container">
    <div class="page-content fadeInDown">
      <div class='page-accueil'>

        <div class='items-container'>
          <div class="item-masonry">
            <div class="shortcut-menu-icon menu-icon"><i class="icon-shortcut"></i></div>
            <div class="item-title">Raccourcis</div>
            <div class="item-content">
              <ul>
                <li><a target='_blank' href='https://tools.hiteach.fr/presentation/'>Présentation SLAM</a></li>
                <li><a target='_blank' href='https://outlook.office.com/mail/inbox'>Outlook - messagerie JB de la salle</a></li>
                <li><a target='_blank' href='https://sio.jbdelasalle.com/phpmyadmin/'>PhpMyAdmin</a></li>
                <li><a target='_blank' href='https://sio.jbdelasalle.com/'>Intranet JB de la salle</a></li>
              </ul>
            </div>
          </div>

          <div class="item-masonry">
            <div class="td-menu-icon menu-icon"><i class="icon-cours"></i></div>
            <div class="item-title">Derniers cours</div>
            <div class="item-content">
              <ul v-if="lastCours.length > 0" class="liste-support">
                <li v-for="element in lastCours" :key="element.id">
                  <a class="link-support" :href="getURLToPage(element)" v-on:click='redirectToPage($event, element)'>
                    <div  class="miniature-support-container">
                      <img :src="getMiniature(element)" @error="errorImageLoading" class="miniature-support" alt="" draggable="false"/>
                    </div>
                    <div class="libelle-support">
                      <span class="text-support-container" v-html="element.titre"></span>
                      <span class="text-theme" v-html="element.Theme.titre"></span>
                    </div>
                  </a>
                </li>
              </ul>
              <span v-else>Aucun élément à afficher</span>
            </div>
          </div>

          <div class="item-masonry">
            <div class="tp-menu-icon menu-icon"><i class="icon-tp"></i></div>
            <div class="item-title">Derniers TP</div>
            <div class="item-content">
                <ul v-if="lastTPs.length > 0" class="liste-support">
                <li v-for="element in lastTPs" :key="element.id">
                  <a class="link-support" :href="getURLToPage(element)" v-on:click='redirectToPage($event, element)'>
                    <div  class="miniature-support-container">
                      <img :src="getMiniature(element)" @error="errorImageLoading" class="miniature-support" alt="" draggable="false"/>
                    </div>
                    <div class="libelle-support">
                      <span class="text-support-container" v-html="element.titre"></span>
                      <span class="text-theme" v-html="element.Theme.titre"></span>
                    </div>
                  </a>
                </li>
              </ul>
              <span v-else>Aucun élément à afficher</span>
            </div>
          </div>

          <div class="item-masonry">
            <div class="tuto-menu-icon menu-icon"><i class="icon-td"></i></div>
            <div class="item-title">Derniers TD</div>
            <div class="item-content">
                <ul v-if="lastTDs.length > 0" class="liste-support">
                <li v-for="element in lastTDs" :key="element.id">
                  <a class="link-support" :href="getURLToPage(element)" v-on:click='redirectToPage($event, element)'>
                    <div  class="miniature-support-container">
                      <img :src="getMiniature(element)" @error="errorImageLoading" class="miniature-support" alt="" draggable="false"/>
                    </div>
                    <div class="libelle-support">
                      <span class="text-support-container" v-html="element.titre"></span>
                      <span class="text-theme" v-html="element.Theme.titre"></span>
                    </div>
                  </a>
                </li>
              </ul>
              <span v-else>Aucun élément à afficher</span>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<style src='./homePage.css' scoped></style>

<script>
import Api from '@/services/Api'
import router from '@/router'
import moment from 'moment/moment'

export default {
  name: 'HomePage',
  data () {
    return {
      user: {
      },
      lastTDs: [],
      lastTPs: [],
      lastCours: [],
      items : [
      {
        title: 'First',
        description: 'The first item.'
      },
      {
        title: 'Second',
        description: 'The second item.'
      },
    ]
    }
  },
  components: {
  },
  methods: {
    errorImageLoading: function (event) {
      event.target.src = "/images/undefined.svg"
    },
    getUserData: function () {
      let self = this

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().get('/users/current', configUrl)
        .then((response) => {
          self['user'] = response.data.user
        })
        .catch(() => {
        })
    },
    getCurrentDate: function () {
      let date = moment().format('DD/MM/YYYY')
      return date
    },
    getMiniature: function (support) {
      if (!support.locked) {
        if (Object.prototype.hasOwnProperty.call(support, 'miniatureName') && support.miniatureName !== null) {
          let pathImage = process.env.VUE_APP_CDN_PATH + '/supports/' + support.id + '/cover/' + support.miniatureName
          return pathImage
        } else {
          return '/images/undefined.svg'
        }
      } else {
        return '/images/locked.svg'
      }
    },
    getLastSupports: async function (typeCourse) {
      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        },
        params: {
          typeCourse: typeCourse
        }
      }

      let response = await Api().get('/supports/latest', configUrl)
      if (typeCourse === 'Cours') this.lastCours = response.data.supports
      else if (typeCourse === 'TP') this.lastTPs = response.data.supports
      else this.lastTDs = response.data.supports
    },
    redirectToPage: function (event, support) {
      event.preventDefault()

      if (support.url) {
        window.open(support.url)
      } else {
        let urlPage = '/supports/' + support.Theme.code + '/' + support.slugtitre

        if (router.currentRoute.path !== urlPage) {
          router.push(urlPage)
        }
      }
    },
    getURLToPage: function (support) {
      let urlPage = ''
      if (support.url) {
        urlPage = support.url
      } else {
        urlPage = '/supports/' + support.Theme.code + '/' + support.slugtitre
      }

      return urlPage
    }
  },
  mounted () {
    document.title = 'Accueil'

    this.getUserData()
    this.getLastSupports('Cours')
    this.getLastSupports('TP')
    this.getLastSupports('TD')
  }
}
</script>
