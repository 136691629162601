<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header><h3>Création d'un utilisateur</h3></template>

    <template v-slot:body>
    </template>

    <template v-slot:footer>
      <form id='form-modal' ref='form' v-on:submit='createUser'>
        <div class='form-content'>
          <div class='container-champ'>
            <label class='label-champ'>Identifiant : </label>
            <input spellcheck='false' class='textfield-champ' type='text' name='pseudo' placeholder='Pseudo' autocomplete='off'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Mot de passe : </label>
            <input spellcheck='false' class='textfield-champ' type='password' name='mdp' placeholder='Mot de passe' autocomplete='off'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Nom : </label>
            <input spellcheck='false' class='textfield-champ' type='text' name='nom' placeholder='Nom' autocomplete='off'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Prénom : </label>
            <input spellcheck='false' class='textfield-champ' type='text' name='prenom' placeholder='Prénom' autocomplete='off'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Groupe : </label>
            <div class="formfield-select--container">
              <select name='group'>
                <option v-for="group in groups" :key="group.id" :value='group.id'>
                  {{ group.titre }}
                </option>
              </select>
            </div>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Email : </label>
            <input spellcheck='false' class='textfield-champ' type='text' name='email' placeholder='Email' autocomplete='off'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Date de naissance : </label>
            <input spellcheck='false' class='textfield-champ' type='date' v-model='dateModel' name='dateNaissance' placeholder='JJ/MM/AAAA' autocomplete='off'>
          </div>

          <div class='buttons-container'>
            <input type='submit' class='buttonPrimary' value='Valider'/>
            <a @click="closeModal">
              Annuler
            </a>
          </div>
        </div>
      </form>
    </template>
  </BasicModal>
</template>

<style src='./createUserModal.css' scoped></style>

<script>
import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'CreateUserModal',
  data () {
    return {
      showedModal: false,
      dateModel: '2000-01-01',
      groups: []
    }
  },
  components: {
    BasicModal
  },
  methods: {
    createUser: function (e) {
      e.preventDefault()

      let data = {
        pseudo: e.target.elements.pseudo.value,
        password: e.target.elements.mdp.value,
        nom: e.target.elements.nom.value,
        prenom: e.target.elements.prenom.value,
        groupeId: e.target.elements.group.value,
        email: e.target.elements.email.value,
        dateNaissance: e.target.elements.dateNaissance.value
      }

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().post('/users', data, configUrl)
        .then(() => {
          this.closeModal(null)
          this.$emit('refresh', true)
        })
        .catch(() => {
          this.closeModal(null)
        })
    },
    showModal: function () {
      this.showedModal = true

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().get('/users/groups', configUrl)
        .then((response) => {
          this.groups = response.data.groups
        })
        .catch(() => {
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }
      this.showedModal = false
    }
  },
  mounted () {
    this.emitter.on("openModalCreateUser", () => {
      this.showModal()
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
