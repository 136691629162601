<template>
  <div class='container-area'>
    <div class='area-input-container'>
      <textarea v-on:keydown='tabulationFormat' spellcheck='false' class='area-input' ref="areaReponse" @blur='saveReponse($event, currentBlock.id)' @input='resizeBlock()' :value='getReponse(currentBlock)'></textarea>
     </div>
  </div>
</template>

<style src='./areaReponse.css' scoped></style>

<script>
import router from '@/router'
import Api from '@/services/Api'

export default {
  name: 'AreaReponse',
  props: {
    currentBlock: {}
  },
  data () {
    return {
    }
  },
  methods: {
    redirectToPage: function (event, pageName) {
      event.preventDefault()

      if (router.currentRoute.path !== '/' + pageName) {
        router.push('/' + pageName)
      }
    },
    getReponse: function (block) {
      if (block.Reponses[0] && block.Reponses[0].contenu) {
        return block.Reponses[0].contenu
      }
    },
    resizeBlock: function () {
      setTimeout(() => {  
        let reponseArea = this.$refs['areaReponse']
        reponseArea.style.height = 0
        let newHeight = reponseArea.scrollHeight

        reponseArea.style.height = newHeight + "px"
      }, 1);
    },
    saveReponse: function (event, idBlock) {
      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }
      let data = {
        idBlock: idBlock,
        content: event.target.value
      }

      Api().post('/blocks/saveReponse', data, configUrl)
        .then(() => {
          this.$emit('refresh', true)

          this.updateFrameContent(event.target.value)
        })
        .catch(() => {
        })
    }
  },
  mounted () {
    this.resizeBlock()
  }
}
</script>
