<template>
  <div class="page-container">
    <div class="page-content">

      <div class="buttons-supports" v-if="isTeacher && displayEdition">
        <button class="buttonSecondary" v-on:click="openModal('CreateArea', { idSupport: currentSupport.id })">Zone de réponse</button>
        <button class="buttonSecondary" v-on:click="openModal('CreateAreaCSS', { idSupport: currentSupport.id })">Zone CSS</button>
        <button class="buttonSecondary" v-on:click="openModal('CreateBlock', { idSupport: currentSupport.id })">Texte</button>
        <button class="buttonSecondary" v-on:click="openModal('CreateCode', { idSupport: currentSupport.id })">Code</button>
        <button class="buttonSecondary" v-on:click="openModal('CreateTitre', { idSupport: currentSupport.id })">Titre</button>
      </div>

      <div class="page-course">
        <article>
          <header class="header-support" ref='top' id='top'>
            <div class="title-page-container">
              <div class="miniature-support-current">
                <img :src="getMiniature(currentSupport)" @error="errorImageLoading" alt="" draggable="false"/>
              </div>
              <div class="container-title-support">
                <h1 class="title-support" v-html="formatTitleCourse(currentSupport)"></h1>
              </div>
            </div>
            <div class="breadcrumb" v-if="currentSupport.Theme">
              <a v-bind:href="'/supports/' + currentSupport.Theme.code" v-on:click="redirectToPage">{{ currentSupport.Theme.titre }}</a>
              <span> > </span>
              <span v-html="formatTitleCourse(currentSupport)"></span>
            </div>
          </header>

          <draggable class='blocks-container' v-model="currentSupport.Blocs" item-key="id" @change="updateOrderBlock($event)" handle=".handle" @start="dragging=true" @end="dragging=false">
            <template #item="{element}">
              <section class='bloc-container' v-bind:class='getBlocContainerClass(element)' :id='element.id' ref='element.id' >
                <div class="tooltip-block" v-if="isTeacher && displayEdition">
                  <div class="container-infos-block">
                    <span>{{ element.ordre }}</span>
                  </div>
                  <div class="icons-container">
                    <i class="icon-move handle"></i>

                    <i class="icon-edit" v-on:click="openModal('EditAreaCSS', { idSupport: currentSupport.id, idBloc: element.id})" v-if="element.Bloctype && element.Bloctype.titre.includes('areacss')"></i>
                    <i class="icon-edit" v-on:click="openModal('EditArea', { idSupport: currentSupport.id, idBloc: element.id})" v-else-if="element.Bloctype && element.Bloctype.titre.includes('area')"></i>
                    <i class="icon-edit" v-on:click="openModal('EditCode', { idSupport: currentSupport.id, idBloc: element.id})" v-else-if="element.Bloctype && element.Bloctype.titre == 'code'"></i>
                    <i class="icon-edit" v-on:click="openModal('EditTitre', { idSupport: currentSupport.id, idBloc: element.id})" v-else-if="element.Bloctype && element.Bloctype.titre.includes('title')"></i>
                    <i class="icon-edit" v-on:click="openModal('EditBlock', { idSupport: currentSupport.id, idBloc: element.id})" v-else></i>

                    <i class="icon-delete" v-on:click="openModal('DeleteBlock', { idBloc: element.id})" ></i>
                  </div>
                </div>

                <div class='bloc-content bloc-info' v-if="element.Bloctype && element.Bloctype.titre == 'info'">
                  <div class='bloc-text' v-html="element.contenu"></div>
                </div>
                <div class='bloc-content bloc-danger' v-else-if="element.Bloctype && element.Bloctype.titre == 'danger'">
                  <div class='bloc-text' v-html="element.contenu"></div>
                </div>
                <div class='bloc-content bloc-lore' v-else-if="element.Bloctype && element.Bloctype.titre == 'lore'">
                  <div class='bloc-text' v-html="element.contenu"></div>
                </div>
                <div class='bloc-content bloc-titre1' v-else-if="element.Bloctype && element.Bloctype.titre == 'title1'">
                  <div class='bloc-text' v-html="element.title"></div>
                </div>
                <div class='bloc-content bloc-titre2' v-else-if="element.Bloctype && element.Bloctype.titre == 'title2'">
                  <div class='bloc-text' v-html="element.title"></div>
                </div>
                <div class='bloc-content bloc-instructions' v-else-if="element.Bloctype && element.Bloctype.titre == 'instructions'">
                  <div class='bloc-text' v-html="element.contenu"></div>
                </div>
                <div v-else-if="element.Bloctype && element.Bloctype.titre == 'code'">
                  <highlightjs autodetect :code="element.contenu"/>
                </div>
                <div v-bind:class='getAnswerClass()' v-else-if="element.Bloctype && element.Bloctype.titre == 'area'">
                  <AreaReponse v-bind:currentBlock="element" @refresh="getSupport()"></AreaReponse>
                </div>
                <div v-bind:class='getAnswerClass()' v-else-if="element.Bloctype && element.Bloctype.titre == 'areahtml'">
                  <AreaHtml v-bind:currentBlock="element" @refresh="getSupport()"></AreaHtml>
                </div>
                <div v-bind:class='getAnswerClass()' v-else-if="element.Bloctype && element.Bloctype.titre == 'areacss'">
                  <AreaCss v-bind:currentBlock="element" @refresh="getSupport()"></AreaCss>
                </div>
                <div class='bloc-content' v-else>
                  <div class='bloc-text' v-html="element.contenu"></div>
                </div>
              </section>
            </template>
          </draggable>
        </article>

        <CreateAreaCssModal @refresh="getSupport()"/>
        <EditAreaCssModal @refresh="getSupport()"/>

        <CreateAreaModal @refresh="getSupport()"/>
        <EditAreaModal @refresh="getSupport()"/>

        <CreateBlockTitreModal @refresh="getSupport()"/>
        <EditBlockTitreModal @refresh="getSupport()"/>

        <CreateBlockModal @refresh="getSupport()"/>
        <EditBlockModal @refresh="getSupport()"/>
        <DeleteBlockModal @refresh="getSupport()"/>

        <CreateCodeModal @refresh="getSupport()"/>
        <EditCodeModal @refresh="getSupport()"/>

      </div>
    </div>

    <aside>
      <div class="wrapper-right-sidebar" v-bind:class='getSideBarClass()'>

        <div class="container-right-sidebar">
          <div class='sommaire-title' v-if="currentSupport.Supporttype" v-html="currentSupport.Supporttype.titre + ' - ' + currentSupport.titre" v-on:click="scrollToElement('top')"></div>
          <ul class="sommaire-container">
            <li v-for="block in blocksSommaire" :key="block.id" class='sommaire-part-container'>
              <span class="sommaire-part sommaire-title1" v-if="block.Bloctype && block.Bloctype.titre == 'title1'" v-html="block.title" v-bind:href="'#' + block.id" v-on:click="scrollToElement(block.id)"></span>
              <span class="sommaire-part sommaire-title2" v-else-if="block.Bloctype && block.Bloctype.titre == 'title2'" v-html="block.title" v-bind:href="'#' + block.id" v-on:click="scrollToElement(block.id)"></span>
            </li>
          </ul>
        </div>

      </div>

      <div class='button-toggle' v-on:click='toggleBar'>
        <i v-bind:class='showBar !== "showed" ? "icon-before" : "icon-next"'></i>
      </div>
    </aside>
  </div>
</template>

<style src='./supportPage.css' scoped></style>

<script src="./supportPage.js"></script>
