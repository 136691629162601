<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header><h3>Supprimer ce support ?</h3></template>

    <template v-slot:body>
    </template>

    <template v-slot:footer>
      <div class='buttons-container'>
        <button class="buttonPrimary" @click="deleteSupport">
          OK
        </button>
        <a @click="closeModal">
          Annuler
        </a>
      </div>
    </template>
  </BasicModal>
</template>

<style src='../BasicModal/basicModal.css' scoped></style>

<script>
import Api from '@/services/Api'
import ApiCdn from '@/services/ApiCdn'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'DeleteSupportModal',
  data () {
    return {
      showedModal: false,
      idSupport: 0
    }
  },
  components: {
    BasicModal
  },
  methods: {
    deleteSupport: function () {
      const supportId = this.idSupport
      let configUrl = {
        data: {
          id: supportId
        },
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().delete('/supports', configUrl)
        .then(async () => {
          await ApiCdn().delete('/supports', configUrl)

          this.$emit('refresh', true)
          this.showedModal = false
        })
        .catch(() => {
          this.showedModal = false
        })
    },
    showModal: function (idSupport) {
      this.idSupport = idSupport
      this.showedModal = true
    },
    closeModal: function (e) {
      if (e != null)
        e.preventDefault()
      this.showedModal = false
    }
  },
  mounted () {
    this.emitter.on("openModalDeleteSupport", params => {
      this.showModal(params.idSupport)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
