<template>
  <div id='page-login'>
    <form id='form-connexion' class='fadeInDown' v-on:submit='launchConnection'>
      <div class='form-content'>
        <div class='container_logo'>
          <img src='/images/logo-img.png' alt=''>
        </div>
        <div class='container-logo-text'>
          <label>Hiteach</label>
        </div>

        <div class='container_connexion_champ' v-bind:class='{ "container-input-error": retour !== "okay" }'>
          <input spellcheck='false' class='textfield_champ_connexion' v-bind:class='{ "input-error": retour !== "okay" }' type='text' name='username' id='username' placeholder='Identifiant' autocomplete='username' required>
        </div>

        <div class='container_connexion_champ' v-bind:class='{ "container-input-error": retour !== "okay"}'>
          <input spellcheck='false' class='textfield_champ_connexion' v-bind:class='{ "input-error": retour !== "okay" }' type='password' name='mdp' id='password' placeholder='Mot de passe' autocomplete='off' required>
        </div>

        <div class='container-error-text'>
          <div class='error-text' v-if='retour === "identification"'>Identifiant ou mot de passe incorrect</div>
          <div class='error-text' v-if='retour === "access"'>Accès non autorisé</div>
        </div>

        <div id='containerButton'>
          <input type='submit' class='buttonPrimary' id='submit-connexion' value='Connexion'/>
        </div>

        <div class='link-forgot-password'>
          <a href='/forgotPassword' v-on:click='redirect'>Mot de passe oublié ?</a>
        </div>
      </div>
    </form>
  </div>
</template>

<style src='./loginPage.css' scoped></style>

<script>

import router from '@/router'
import Auth from '@/mixins/Auth'

export default {
  name: 'LoginPage',
  data () {
    return {
      retour: 'okay'
    }
  },
  methods: {
    launchConnection: async function (e) {
      e.preventDefault()

      let data = {
        pseudo: e.target.elements.username.value,
        password: e.target.elements.mdp.value
      }

      try {
        await Auth.methods.login(data)
        router.push('/home')
      } catch (errors) {
        if (errors.response.status === 403) {
          this.retour = 'access'
        } else {
          this.retour = 'identification'
        }
        localStorage.removeItem('user-token')

        setTimeout(function () {
          this.retour = 'okay'
        }, 2500)
      }
    },
    redirect: function (e) {
      e.preventDefault()
      router.push('/forgotPassword')
    }
  }
}
</script>
